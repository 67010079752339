import type { NextPage } from 'next'
import Image from 'next/image'
import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useRouter } from '@hozana/router'
import { nl2br } from '@hozana/utils/functions/strings'

import { Button } from 'elements/button/Button'
import { Div } from 'elements/layout/Div'
import { Link } from 'elements/text/Link'
import { P } from 'elements/text/P'
import { Text } from 'elements/text/Text'

import { FullPageDiv } from 'app/structure/FullPageDiv'
import { Head } from 'app/structure/Head'
import { Page } from 'app/structure/Page'
import { PAGE } from 'routes/constants'
import { deleteAllCaches, unregisterAllServiceWorkers } from 'sw/functions'

const drawings = {
  403: '/svg/drawing_access_forbidden.svg',
  404: '/svg/drawing_notfound.svg',
  410: '/svg/drawing_notfound.svg',
  500: '/svg/drawing_500_error.svg',
  503: '/svg/drawing_500_error.svg',
}

const getTranslationStatusCodeTitle = (code: keyof typeof drawings) => {
  switch (code) {
    case 403:
      return 'trans:common:error.403.title'
    case 404:
      return 'trans:common:error.404.title'
    case 410:
      return 'trans:common:error.410.title'
    case 500:
      return 'trans:common:error.500.title'
    case 503:
      return 'trans:common:error.503.title'
    default:
      return `trans:common:error.${code}.title`
  }
}

const getTranslationStatusCodeMessage = (code: keyof typeof drawings) => {
  switch (code) {
    case 403:
      return 'common:error.403.message'
    case 404:
      return 'common:error.404.message'
    case 410:
      return 'common:error.404.message'
    case 500:
      return 'common:error.500.message'
    case 503:
      return 'common:error.503.message'
    default:
      return 'common:error.500.message'
  }
}

export const ErrorPage: NextPage<{ statusCode: number }> = ({ statusCode }) => {
  const code = (statusCode && statusCode in drawings ? statusCode : 500) as keyof typeof drawings

  const { locale } = useRouter()
  const { t } = useTranslation()

  // Clear service worker registrations and caches
  useEffect(() => {
    deleteAllCaches()
    unregisterAllServiceWorkers()
  }, [])

  return (
    <Page metaTitle={getTranslationStatusCodeTitle(code)}>
      <Head isIndexed={false} />
      <FullPageDiv p="40px 20px 20px">
        <Div align="center" h="150px">
          <Image src={drawings[code]} alt={`error_${code}`} width="150px" height="150px" />
          <P fontSize={{ xs: '1.4em', sm: '1.6em' }} bold m="0 0 20px">
            {getTranslationStatusCodeTitle(code)}
          </P>
          <P fontSize={{ xs: '1em', sm: '1.4em' }} m="0 0 20px">
            <Trans i18nKey={getTranslationStatusCodeMessage(code)} components={{ br: <br /> }} />
          </P>
          {code === 404 && !['it', 'pl'].includes(locale) ? (
            <>
              <Link asButton to={{ pathname: PAGE.COMMUNITY_LIST }} reload>
                <Button size="bigger" colors="yellow">
                  trans:common:community.communities.view-all
                </Button>
              </Link>
              <div>
                <Text fontSize={{ xs: '1em', sm: '1em' }} m="20px" italic>
                  {nl2br(t('common:error.404.quote'))}
                </Text>
              </div>
            </>
          ) : code === 410 && !['it', 'pl'].includes(locale) ? (
            <Link asButton to={{ pathname: PAGE.COMMUNITY_LIST }} reload>
              <Button size="bigger" colors="yellow">
                trans:common:community.communities.view-all
              </Button>
            </Link>
          ) : (
            <Link to={{ pathname: PAGE.CONTACT }}>
              <Button size="bigger" colors="yellow">
                trans:common:words.contact-us
              </Button>
            </Link>
          )}
        </Div>
      </FullPageDiv>
    </Page>
  )
}

ErrorPage.getInitialProps = ({ err, res }) => {
  const currentStatusCode = res?.statusCode
  const throwedStatusCode = err?.statusCode

  const statusCode = throwedStatusCode || currentStatusCode || 500

  if (res) {
    res.statusCode = statusCode
  }

  return { statusCode }
}
